.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #b35f00;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0; /* Initially hide the button */
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top.visible {
    opacity: 1; /* Show the button when visible */
  }
  
  .scroll-to-top:hover {
    background-color: #ffae00;
  }
  
  
.section-pricing {
  padding: 9.6rem 0;
}

.pricing-plan {
 
  width: 75%;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0,0.5);
  border-radius: 5%;
}

.pricing-plan--starter {
  justify-self: end;
  border: 2px solid #fdf2e9;
  padding: 4.6rem;
}

.pricing-plan--complete {
  background-color: #fdf2e9;
  padding: 4.8rem;
  position: relative;
  overflow: hidden;
}

.pricing-plan--complete::after {
  /* content: "Best value"; */
  position: absolute;
  top: 6%;
  right: -18%;

  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  background-color: #ffd43b;
  padding: 0.8rem 8rem;
  transform: rotate(45deg);
}

.plan-header {
  text-align: center;
  margin-bottom: 4.8rem;
}

.plan-name {
  color: #cf711f;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.75;
  margin-bottom: 3.2rem;
}

.plan-price {
  font-size: 6.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.6rem;
}

.plan-price span {
  font-size: 3rem;
  font-weight: 500;
  margin-right: 0.8rem;
}

.plan-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #6f6f6f;
}

.plan-sign-up {
  text-align: center;
  margin-top: 4.8rem;
}

.plan-details {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
}

.feature-icon {
  color: #e67e22;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #fdf2e9;
  margin-bottom: 3.2rem;
  padding: 1.6rem;
  border-radius: 50%;
}

.feature-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

.feature-faded {
  color: #6f6f6f;
}


.larger-icon {
  font-size: 24px !important;
  
}
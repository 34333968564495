/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6
Large: 1.8


- Letter spacing
-0.5px
0.75px

--- 02 COLORS

- Primary: #e67e22
- Tints: 
#fdf2e9
#fae5d3
#eb984e

- Shades:
#cf711f
#45260a

- Accents:
- Greys: 
#888
#767676 (lightest grey allowed on #fff)
#6f6f6f (lightest grey allowed on #fdf2e9)
#555
#333

--- 05 SHADOWS

0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;

  /* scroll-behavior: smooth; */
}

body {
  font-family: "Rubik", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;

  /* Only works if there is nothing absolutely positioned in relation to body */
  overflow-x: hidden;
}

/**************************/
/* GENERAL COMPONENTS */
/**************************/
.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 9.6rem;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  /* Remove existing color: */
  color: transparent;
  /* New gradient property: */
  background-image: linear-gradient(to right, #df6e0a 0%, #5b0849 100%);
  /* Adjust gradient angles and colors as desired */
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #cf711f;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

strong {
  font-weight: 500;
}

.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
  padding: 1.6rem 3.2rem;
  border-radius: 9px;

  /* Only necessary for .btn */
  border: none;
  cursor: pointer;
  font-family: inherit;

  /* Put transition on original "state" */
  /* transition: background-color 0.3s; */
  transition: all 0.3s;
}

.btn--full,
.btn--full:link,
.btn--full:visited {
  background-color: #e67e22;
  color: #fff;
}

.btn--full:hover,
.btn--full:active {
  background-color: #cf711f;
}

.btn--outline,
.btn--outline:link,
.btn--outline:visited {
  background-color: #fff;
  color: #555;
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #fdf2e9;

  /* border: 3px solid #fff; */
  /* Trick to add border inside */
  box-shadow: inset 0 0 0 3px #fff;
}

.btn--form {
  background-color: #45260a;
  color: #fdf2e9;
  align-self: end;
  padding: 1.2rem;
}

.btn--form:hover {
  background-color: #fff;
  color: #555;
}

.link,
.link:link,
.link:visited {
  display: inline-block;
  color: #e67e22;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  cursor: pointer;
  color: #cf711f;
  border-bottom: 1px solid transparent;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  line-height: 1.2;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: #e67e22;
}

/* *:focus {
  outline: none;
  outline: 4px dotted #e67e22;
  outline-offset: 8px;
  box-shadow: 0 0 0 0.8rem rgba(230, 125, 34, 0.5);
} */

/***************************/
/* HELPER/SETTINGS CLASSES */
/***************************/

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.center-text {
  text-align: center;
}

/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

/* 1344px / 16 = 84 */
@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 3rem;
  }

  .hero-description {
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 4.8rem;  
  }
  
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}



/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

/* 1200px / 16 = 75 */
@media (max-width: 75em) {
  html {
    /* 9px / 16px  */
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }

  .hero {
    gap: 4.8rem;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }
}



/**************************/
/* BELOW 944px (Tablets) */
/**************************/

/* 944px / 16 = 59 */
@media (max-width: 59em) {
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 50%;
  }

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }


  .hero-img-box {
    text-align: center;
  }

  /* .hero-text-box
  {
    text-align: justify;
  } */

  .faq-img{
    width: 60%;
  }


  .hero-img {
    width: 60%;
  }

 

  .delivered-meals {
    justify-content: center;
    margin-top: 3.2rem;
  }

  .logos img {
    height: 2.4rem;
  }

  .step-number {
    font-size: 7.4rem;
  }

  .meal-content {
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }

  .cta {
    /* 3/5 = 60% + 2/5 = 40% */
    grid-template-columns: 3fr 2fr;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .btn--form {
    margin-top: 1.2rem;
  }

  /* MOBILE NAVIGATION */
  .btn-mobile-nav {
    display: flex;
    z-index: 9999;
  }

  .main-nav {
    background-color: #fdf2e9;
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 80vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: all 0.5s ease-in; */

    /* Hide navigation */
    /* Allows NO transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0  ;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
    box-shadow: 0 2.4rem 2.8rem rgba(6, 6, 6, 0.5);
    border-radius: 3%;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link,
  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

/* 704px / 16 = 44 */
@media (max-width: 44em) {
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .diets {
    grid-column: 1 / -1;
    justify-self: center;
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .pricing-plan {
    width: 100%;
  }

  .grid--footer {
    grid-template-columns: repeat(6, 1fr);
  }

  .logo-col,
  .address-col {
    grid-column: span 3;
  }

  .nav-col {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 3.2rem;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

/* 544px / 16 = 34 */
@media (max-width: 34em) {
  .grid {
    row-gap: 4.8rem;
  }

  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2.4rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

   .faq-img {
    width: 80%;
  }

  .logos img {
    height: 1.2rem;
  }

  .step-img-box:nth-child(2) {
    grid-row: 1;
  }

  .step-img-box:nth-child(6) {
    grid-row: 5;
  }

  .step-img-box {
    transform: translateY(2.4rem);
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-text-box {
    padding: 3.2rem;
  }

  .cta-img-box {
    height: 32rem;
    grid-row: 1;
  }
}

.faq-section {
    background-color: #fdf2e9;
    padding: 4.8rem 0 9.6rem 0;
    
  }
  
  .faq-item {
    margin-bottom: 4.8rem;
  }
  
  .faq-question {
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    padding: 1.6rem 2.4rem;
    border-radius: 0.8rem;
    background-color: #fdf2e9;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
  align-items: center;
    justify-content: flex-start; /* Align to the left */
  }
  
  .faq-question:hover {
    background-color: #fae1cd;
  }
  
  .faq-answer {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-top: 1.6rem;
    padding: 2.4rem;
    border-left: 0.4rem solid #cf711f;
    background-color: #fff;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  }
  

  .faq-section-container {
    padding: 0 3.2rem; /* Adjust the padding as needed */
  }
  
  /* Add your existing styles for .faq-section, .faq-item, .faq-question, and .faq-answer */
  
  .faq-section-container {
    padding: 0 3.2rem; /* Adjust the padding as needed */
  }
  
  .faq-question {
    display: flex;
    align-items: center;
  }
  
  .expand-button {
    margin-left: auto;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .faq-answer {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-top: 1.6rem;
    padding: 2.4rem;
    border-left: 0.4rem solid #cf711f;
    background-color: #fff;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  }
  

  .faq-question {
    display: flex;
    align-items: center;
  }
  
  .expand-button {
    margin-right: 0.5rem; /* Add margin to create space between the button and the question */
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .faq-question span {
    flex: 1; /* Ensure the question takes up the remaining space */
  }
  
  .expand-button {
    margin-right: 0.5rem; /* Add margin to create space between the button and the question */
    background-color: #cf711f; /* Set background color */
    color: #fff; /* Set text color */
    border: none;
    border-radius: 50%; /* Make button circular */
    width: 2rem; /* Set button width */
    height: 2rem; /* Set button height */
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease; /* Add transition effect */
  }
  
  .expand-button:hover {
    background-color: #b25f14; /* Change background color on hover */
  }
  
  .faq-img {
    width: 60%;
    box-shadow: 0 1.2rem 2.4rem rgb(51, 49, 47);
    border-radius: 5%;
    
  }
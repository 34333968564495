.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdf2e9;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.5);

  /* Because we want header to be sticky later */
  height: 9.6rem;
  padding: 0 4.8rem;
  position: relative;
}

.logo {
  height: 2.2rem;
  cursor: pointer;
  /* box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0,0.5); */
  border-radius: 5%;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.main-nav-link,
.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 200;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  cursor: pointer;
  color: #cf711f;
}

.main-nav-link.nav-cta,
.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #e67e22;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #cf711f;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  font-weight: 500;
  font-size: 3.8rem;
  display: none;
}



/* Define width and height for the burger icon */
.btn-mobile-nav .burger-icon {
  width: 50px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}

/* Additional styling for the burger icon (optional) */
.btn-mobile-nav .burger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000; /* Adjust the color as needed */
  margin-bottom: 5px; /* Adjust spacing between lines */
}

.icon-mobile-nav {
  height: 22.8rem;
  width: 22.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/* STICKY NAVIGATION */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.5);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/* Add styles for the mobile navigation menu */
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10%; /* Adjust the width to occupy 50% of the screen */
  background-color: #fdf2e9; /* Same background color as header */
  z-index: 1000; /* Ensure it's above the header */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4.8rem; /* Adjust top padding to push menu items down */
  padding-bottom: 4.8rem; /* Adjust bottom padding to push menu items up */
  transition: transform 0.3s ease-in-out; /* Add smooth transition for animation */
  transform: translateX(-100%); /* Initially hide the menu off-screen */
}

/* Show the mobile navigation menu when it's active */
.mobile-nav.active {
  transform: translateX(0); /* Slide the menu into view */
}

.mobile-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.mobile-nav-item {
  margin-bottom: 2.4rem; /* Adjust spacing between menu items */
}

.mobile-nav-link {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.4rem; /* Adjust font size for smaller screens */
  transition: color 0.3s;
}

.mobile-nav-link:hover,
.mobile-nav-link:active {
  color: #cf711f;
}

/* Show/hide the close icon */
.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.icon-mobile-nav.active[name="close-outline"] {
  display: block;
}


/* Add styles for the sidebar navigation menu */
.sidebar-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%; /* Adjust the width as needed */
  background-color: #fdf2e9; /* Same background color as header */
  z-index: 1000; /* Ensure it's above the header */
  padding-top: 4.8rem; /* Adjust top padding to push menu items down */
  padding-bottom: 4.8rem; /* Adjust bottom padding to push menu items up */
  overflow-y: auto; /* Allow scrolling if menu items exceed the screen height */
  transition: transform 0.3s ease-in-out; /* Add smooth transition for animation */
  transform: translateX(-100%); /* Initially hide the menu off-screen */
}

/* Show the sidebar navigation menu when it's active */
.sidebar-nav.active {
  transform: translateX(0); /* Slide the menu into view */
}

.sidebar-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.sidebar-nav-item {
  margin-bottom: 2.4rem; /* Adjust spacing between menu items */
}

.sidebar-nav-link {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.4rem; /* Adjust font size for smaller screens */
  transition: color 0.3s;
}

.sidebar-nav-link:hover,
.sidebar-nav-link:active {
  color: #cf711f;
}




.section-cta {
  /* top / right / bottom / left */
  /* padding: 9.6rem 0 12.8rem 0; */

  /* top / horizontal / left */
  padding: 4.8rem 0 12.8rem;
}

.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* background-color: #e67e22; */
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 1);
  border-radius: 11px;
  background-image: linear-gradient(to right bottom, #eb984e, #e67e22);
  overflow: hidden;
}

.cta-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  color: #45260a;
}

.cta .heading-secondary {
  /* color: #45260a; */
  color: inherit;
  margin-bottom: 3.2rem;
}

.cta-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4.8rem;
}

.cta-img-box {
  background-image: linear-gradient(
      to right bottom,
      rgba(235, 151, 78, 0.35),
      rgba(230, 125, 34, 0.35)
    ),
    url("../assets/cta/submit.webp");
  background-size: cover;
  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.cta-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.cta-form input,
.cta-form select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-form input::placeholder {
  color: #aaa;
}

.cta *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(253, 242, 233, 0.5);
}

.cta-form textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 16px; /* Set the font size for regular text */
  font-family: 'Arial', sans-serif; /* Set the font family for regular text */
}

.cta-form textarea::placeholder {
  font-size: 14px; /* Set the font size for placeholder text */
  font-style: italic; /* Optionally set the font style for placeholder text */
  color: #999; /* Optionally set the color for placeholder text */
}

.form-submitted-message {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text color */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px; /* Add some space between the message and the form */
  border: 1px solid #f5c6cb; /* Dark red border */
  font-size: 1.5em; /* Larger font size */
}

.all-recipes {
  text-align: center;
  font-size: 1.5rem;
}

.all-recipes a {
  text-decoration: none; /* Remove underline by default */
}

.all-recipes a:hover {
  color: #b33600; /* Color when hovering over the link */
}

.all-recipes a:visited {
  color: #6c757d; /* Color for visited links */
}

.all-recipes a:active {
  color: #00b324; /* Color when the link is clicked */
}